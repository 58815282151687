<template>
    <header class="page-title">
        <h1><i class="bi bi-user-circle"></i> トーク</h1>
    </header>

    <section class="section">
        <h2 class="section-title">お問い合わせ内容</h2>
        <div class="row mb-3">
            <div class="col-6">
                <div class="form-label">会社名</div>
                <div class="form-readonly">株式会社AD5</div>
            </div>
            <div class="col-6">
                <div class="form-label">担当者名</div>
                <div class="form-readonly">片岡 高志</div>
            </div>
            <div class="col-6">
                <div class="form-label">電話番号</div>
                <div class="form-readonly">06-7777-2147</div>
            </div>
            <div class="col-12">
                <div class="form-label">メールアドレス</div>
                <div class="form-readonly">kataoka@ad5.jp</div>
            </div>
            <div class="col-6">
                <div class="form-label">チャネル</div>
                <div class="form-readonly">Webサイト</div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-36">
                <div class="form-label">お問い合わせ内容</div>
                <div class="form-readonly">プライバシーマークの取得を検討しています。</div>
            </div>
        </div>
    </section>

    <section class="section">
        <ul class="list-group">
            <li class="list-group-item">
                <p class="mb-3 p-3 bg-light">お世話になっております。<br>
                スリーエーコンサルティング・<strong>認証パートナー</strong> の友藤と申します。<br>
                恐れ入りますが <strong>片岡 高志</strong> 様はご在社でしょうか。</p>

                <div class="btn-group" role="group">
                    <input type="radio" class="btn-check" id="btnradio1_1" v-model="q1" :value="1">
                    <label class="btn btn-outline-primary" for="btnradio1_1">いる</label>
                    <input type="radio" class="btn-check" id="btnradio1_2" v-model="q1" :value="2">
                    <label class="btn btn-outline-primary" for="btnradio1_2">いない</label>
                </div>
            </li>

            <li class="list-group-item" v-if="q1 === 2">
                <div class="mb-3 p-3 bg-light">承知いたしました。<br>
                また改めてご連絡をさせていただきます。<br>
                (お問合せ内容)の件で連絡があった旨お伝えください。（しっかり言い切る）<br>
                ※その後メールを送る</div>

                <div class="btn-group" role="group">
                    <input type="checkbox" class="btn-check" id="btnradio2_1">
                    <label class="btn btn-outline-primary" for="btnradio2_1">完了</label>
                </div>
            </li>

            <li class="list-group-item" v-if="q1 !== 2">
                <div class="mb-3 p-3 bg-light">この度 <strong>Webサイト</strong> にてお問い合わせいただきありがとうございます。<br>
                (お問合せ内容) の件でご連絡いたしました。</div>

                <div class="btn-group" role="group">
                    <input type="checkbox" class="btn-check" id="btnradio3_1">
                    <label class="btn btn-outline-primary" for="btnradio3_1">完了</label>
                </div>
            </li>

            <li class="list-group-item" v-if="q1 !== 2">
                <label class="p-3"><input type="checkbox" v-model="q5" :value="true"> 個人っぽい？</label>

                <div v-if="q5">
                    <div class="mb-3 p-3 bg-light">会社名を記載いただいておりませんでしたが、恐れ入りますが、<strong>片岡 高志</strong>様は、会社に所属されておりますでしょうか？</div>

                    <div class="btn-group" role="group">
                        <input type="radio" class="btn-check" id="btnradio30_1" v-model="q6" :value="1">
                        <label class="btn btn-outline-primary" for="btnradio30_1">個人で確定</label>
                        <input type="radio" class="btn-check" id="btnradio30_2" v-model="q6" :value="2">
                        <label class="btn btn-outline-primary" for="btnradio30_2">会社所属だった</label>
                        <input type="radio" class="btn-check" id="btnradio30_3" v-model="q6" :value="3">
                        <label class="btn btn-outline-primary" for="btnradio30_3">会社設立予定</label>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="form-label">会社名</div>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </li>

            <li class="list-group-item" v-if="q6 === 1">
                <div class="mb-3 p-3 bg-light">申し訳ございませんが、企業向けのサポートですので・・・</div>

                <div class="btn-group" role="group">
                    <input type="checkbox" class="btn-check" id="btnradio40_1">
                    <label class="btn btn-outline-primary" for="btnradio40_1">完了</label>
                </div>
            </li>

            <li class="list-group-item" v-if="q1 !== 2 && q6 !== 1">
                <div class="mb-3 p-3 bg-light">(規格)は新規認証のご検討でお間違いないでしょうか。</div>

                <div class="btn-group" role="group">
                    <input type="radio" class="btn-check" id="btnradio4_1" v-model="q2" :value="1">
                    <label class="btn btn-outline-primary" for="btnradio4_1">OK</label>
                    <input type="radio" class="btn-check" id="btnradio4_2" v-model="q2" :value="2">
                    <label class="btn btn-outline-primary" for="btnradio4_2">規格違った/取得済</label>
                    <input type="radio" class="btn-check" id="btnradio4_3" v-model="q2" :value="3">
                    <label class="btn btn-outline-primary" for="btnradio4_3">どちらでもなく勉強のため</label>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="form-label">規格</div>
                        <select class="form-select">
                            <option value="">-- 選択 -- </option>
                        </select>
                    </div>
                </div>
            </li>

            <li class="list-group-item" v-if="q2 === 3">
                <div class="mb-3 p-3 bg-light">取得（検討）企業様向けのサービスですので、申し訳ございませんが・・・</div>

                <div class="btn-group" role="group">
                    <input type="checkbox" class="btn-check" id="btnradio40_1">
                    <label class="btn btn-outline-primary" for="btnradio40_1">完了</label>
                </div>
            </li>

            <li class="list-group-item" v-if="q1 !== 2 && q2 !== 3 && q6 !== 1">
                <div class="mb-3 p-3 bg-light">ありがとうございます。<br>
                弊社の(規格)担当者より無料相談という形でZOOMにて(お問合せ内容)の件についてご説明をさせていただければと存じます。</div>

                <div class="btn-group" role="group">
                    <input type="radio" class="btn-check" id="btnradio5_1" v-model="q3" :value="1">
                    <label class="btn btn-outline-primary" for="btnradio5_1">OK</label>
                    <input type="radio" class="btn-check" id="btnradio5_2" v-model="q3" :value="2">
                    <label class="btn btn-outline-primary" for="btnradio5_2">情報収集、資料のみ希望</label>
                    <input type="radio" class="btn-check" id="btnradio5_3" v-model="q3" :value="3">
                    <label class="btn btn-outline-primary" for="btnradio5_1">打合せ不要、時間とれない</label>
                    <input type="radio" class="btn-check" id="btnradio5_4" v-model="q3" :value="4">
                    <label class="btn btn-outline-primary" for="btnradio5_2">とりあえず見積もりほしい</label>
                </div>
            </li>

            <li class="list-group-item" v-if="q3 === 1">
                <div class="mb-3 p-3 bg-light">直近でしたら〇月〇日〇時が空いておりますが、<br>
                そのお時間、<strong>片岡 高志</strong> 様はいらっしゃいますか？</div>

                <div class="btn-group" role="group">
                    <input type="radio" class="btn-check" id="btnradio6_1" v-model="q4" :value="1">
                    <label class="btn btn-outline-primary" for="btnradio6_1">その日でOK</label>
                    <input type="radio" class="btn-check" id="btnradio6_2" v-model="q4" :value="2">
                    <label class="btn btn-outline-primary" for="btnradio6_2">その日はNG</label>
                    <input type="radio" class="btn-check" id="btnradio6_2" v-model="q4" :value="3">
                    <label class="btn btn-outline-primary" for="btnradio6_2">別日希望</label>
                </div>
            </li>

            <li class="list-group-item" v-if="q3 === 1">
                <div class="mb-3 p-3 bg-light">では〇月〇日〇時に弊社の〇〇がお話させていただきます。</div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-label">アポ日時</div>
                        <input type="date" class="form-control">
                    </div>
                    <div class="col-6">
                        <div class="form-label">営業担当</div>
                        <select class="form-select">
                            <option value="">-- 選択 -- </option>
                        </select>
                    </div>
                </div>
            </li>

            <li class="list-group-item" v-if="q3 === 1">
                <div class="mb-3 p-3 bg-light">(規格)取得に関して他社様のお話を聞かれるご予定はございますか。</div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-label">競合</div>
                        <select class="form-select">
                            <option value="">-- 選択 -- </option>
                        </select>
                    </div>
                </div>
            </li>

            <li class="list-group-item" v-if="q3 === 1">
                <div class="mb-3 p-3 bg-light">かしこまりました。<br>
                ありがとうございます。<br>
                それではお電話切らせていただいた後にメールにて当日使用いたしますZOOMURLをお送りいたします。</div>
                <div class="btn-group" role="group">
                    <input type="checkbox" class="btn-check" id="btnradio7_1">
                    <label class="btn btn-outline-primary" for="btnradio7_1">完了</label>
                </div>
            </li>

            <li class="list-group-item" v-if="q3 === 1">
                <label class="p-3"><input type="checkbox" v-model="q7" :value="true"> 電話反響の場合</label>

                <div v-if="q7">
                    <div class="mb-3 p-3 bg-light">URLをお送りするメールアドレスを教えていただけますでしょうか？</div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <div class="form-label">メールアドレス</div>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="row mt-5">
            <div class="col-18">
                <div class="form-label">特記事項</div>
                <textarea class="form-control" rows="5"></textarea>
            </div>
        </div>

        <div class="mt-5">
            <button class="btn btn-info btn-lg">活動履歴に反映</button>
        </div>
    </section>

</template>

<script>
export default {
    name: 'Talker',
    components: {

    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            q1: null,
            q2: null,
            q3: null,
            q4: null,
            q5: null,
            q6: null,
            q7: null,
        }
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>
